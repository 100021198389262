<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <path
            fill-rule="nonzero"
            d="M2.42029413 0c.09279083 0 .17792641.00773528.26290735.0386764L2.984849.13923508c.37116331.1237645.8505826.6265578.95110599 1.00558659l.7190516 2.64546626c.09286816.35582295-.06178322.98238075-.316958 1.23764504l-.95118333.9591749-.43302387.43317577.16238395.58788139c1.04389683 3.83669958 4.04421097 6.83798878 7.87948796 7.88225178l.5876752.162441.4331012-.4331758.9587612-.9591749c.1701166-.1701762.5877526-.3480877.9975015-.3480877.0773256 0 .1623839.0077353.2397096.0232059l2.6445386.7193811c.3711633.1005587.8815902.5801462 1.005234.9514397l.1005234.3016759c.108256.3248819-.0308529.9127633-.2706399 1.152557l-1.3918625 1.3923507c-.1391862.0618822-.2860277.1082939-.4330238.1469703h-.0309303C7.09076586 18 0 10.9144822 0 2.16587881v-.04641168c.03866285-.13923507.08513559-.27847014.14699614-.40223464L1.53885858.32488182C1.68570006.16244092 2.04913081 0 2.42029413 0"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
