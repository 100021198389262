<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14">
        <path
            fill-rule="nonzero"
            d="m7.00403516 8.03524354 1.48642723 1.48557708c.42836956.42817336.99106542.64216248 1.55371241.64216248.5626959 0 1.1253429-.21398912 1.5537125-.64216248l1.4422866-1.44146166 5.7694392 5.76613944C18.5752379 13.9449533 18.3175228 14 18.0468682 14H1.95315132c-.27065456 0-.52841848-.0550467-.76274488-.1545016l5.81362872-5.81025486ZM19.8705077 1.25295119C19.9539062 1.47001469 20 1.70557348 20 1.95201472V12.0480048c0 .2704998-.0550782.5280675-.15459.7623086l-5.7694392-5.76613942ZM.15459 1.18970608l5.81362872 5.81030368L.15458999 12.8102646C.05507819 12.5760723 0 12.3185046 0 12.0480048V1.95201472c0-.27049977.05507818-.52811626.15459-.76230864ZM18.046878 0c.2943362 0 .5732427.06548993.8235848.18197612l-5.8302792 5.82699337-2.4780786 2.47666128c-.1383302.13825109-.3223147.21442828-.5179692.21442828-.19560567 0-.3795414-.07617719-.51787161-.21442828L1.1904162.15450157C1.4247426.05504668 1.68250653 0 1.95311226 0Z"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
